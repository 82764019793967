<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">服务套餐</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="服务名称">
					<a-input v-model="query.name" placeholder="请输入名称"></a-input>
				</a-form-model-item>
				<a-form-model-item label="状态">
					<a-select placeholder="请选择" v-model="query.status">
						<a-select-option :value="1">
							已发布
						</a-select-option>
						<a-select-option :value="-1">
							已下架
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style="margin-left: 10px">+新增套餐</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="status" slot-scope="text, record">
				{{ record.status == 1 ? '已发布' : '已下架' }}
			</span>
			<span slot="money" slot-scope="text, record">
				{{ record.money }} 元
			</span>

			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">详情</a>
					<a @click="changeStatus(record)"> {{ record.status == 1 ? '下架' : '发布' }}</a>
				</a-space>
			</span>
		</a-table>

	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择分类')); // 验证失败，返回错误信息
			}
		};
		let validateMoney = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入金额'));
			} else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			query: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			pagetotal: 0,
			classPagetotal: 0,
			classList: [],
			checkValue: [],
			packageList: [],
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			other: '',
			customerForm: {
				name: '',
				money: null,
				content: '',
			},
			service_id: null,
			classForm: { name: '' },
			class_id: null,
			rules: {
				name: [
					{ required: true, message: '请输入服务名称', trigger: 'blur' },
				],
				money: [
					{ required: true, message: '请输入金额', trigger: 'blur' },
					{ validator: validateMoney, trigger: 'blur' },
				],
				class_id: [{ required: true, validator: validateClass, message: "请选择类型", trigger: 'change' }],
				content: [
					{ required: true, message: '请输入服务介绍', trigger: 'blur' },
					{ max: 255, message: '服务介绍仅限255字', trigger: 'blur' },
				],
			},

			columns: [
				{
					title: '套餐名称',
					dataIndex: 'name',
				},
				{
					title: '售价',
					scopedSlots: {
						customRender: 'money'
					}
				},
				{
					title: '状态',
					scopedSlots: {
						customRender: 'status'
					}
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			data: [],
		}
	},
	created() {
		this.getList()
	},
	methods: {

		changeStatus(record) {
			this.$post(domain + '/admin/v1/package/changeStatus', { package_id: record.package_id }).then(res => {
				if (res.code === 0) {
					this.$message.success('操作成功')
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toEdit(record) {
			this.$router.push({
				path: '/packageInfo',
				query: {
					key: record.package_id
				}
			})
		},
		add() {
			this.$router.push({
				path: '/packageInfo',
				query: {
					key: -1
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		handleChange(value) {
			console.log(`Selected: ${value}`);
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},

		getList() {
			this.$post(domain + '/admin/v1/package/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
